import React, { useState, useEffect, useRef, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Play, Pause } from 'lucide-react';

// Format time helper function

const formatTime = (seconds) => {
  const mins = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
};

const AudioMessage = ({ audioUrl }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [error, setError] = useState(null);
  const audioRef = useRef(null);

  useEffect(() => {
    const audio = audioRef.current;
    
    const handleLoadedMetadata = () => {
      setDuration(audio.duration);
      setError(null);
    };

    const handleTimeUpdate = () => {
      setCurrentTime(audio.currentTime);
    };

    const handleEnded = () => {
      setIsPlaying(false);
      setCurrentTime(0);
    };

    const handleError = (e) => {
      console.error("Audio error:", e);
      setError("Error loading audio file. Please check the file path and format.");
      setIsPlaying(false);
    };

    audio.addEventListener('loadedmetadata', handleLoadedMetadata);
    audio.addEventListener('timeupdate', handleTimeUpdate);
    audio.addEventListener('ended', handleEnded);
    audio.addEventListener('error', handleError);

    return () => {
      audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
      audio.removeEventListener('timeupdate', handleTimeUpdate);
      audio.removeEventListener('ended', handleEnded);
      audio.removeEventListener('error', handleError);
    };
  }, []);

  const togglePlay = () => {
    if (!audioRef.current || error) return;
    
    if (isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
    } else {
      const playPromise = audioRef.current.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            setIsPlaying(true);
            setError(null);
          })
          .catch(error => {
            console.error("Error playing audio:", error);
            setError("Error playing audio. Please try again.");
            setIsPlaying(false);
          });
      }
    }
  };

  const handleSeek = (e) => {
    if (!audioRef.current || error) return;
    const seekTime = (e.target.value / 100) * duration;
    audioRef.current.currentTime = seekTime;
    setCurrentTime(seekTime);
  };

  const progress = duration ? (currentTime / duration) * 100 : 0;

  return (
    <div className="flex flex-col space-y-2 bg-gray-800 rounded-lg p-4 w-full max-w-md">
      <audio ref={audioRef} preload="metadata" className="hidden">
        <source src={audioUrl} type="audio/mpeg" />
        <source src={audioUrl.replace('.mp3', '.ogg')} type="audio/ogg" />
        <source src={audioUrl.replace('.mp3', '.wav')} type="audio/wav" />
        Your browser does not support the audio element.
      </audio>
      
      {error ? (
        <div className="text-red-500 text-sm">{error}</div>
      ) : (
        <div className="flex items-center space-x-4">
          <button
            onClick={togglePlay}
            className="w-10 h-10 flex items-center justify-center bg-blue-500 rounded-full text-white hover:bg-blue-600 transition-colors"
            aria-label={isPlaying ? 'Pause' : 'Play'}
            disabled={!!error}
          >
            {isPlaying ? <Pause size={20} /> : <Play size={20} />}
          </button>
          
          <div className="flex-1 space-y-1">
            <div className="flex justify-between text-sm text-gray-300">
              <span>{formatTime(currentTime)}</span>
              <span>{formatTime(duration || 0)}</span>
            </div>
            
            <input
              type="range"
              min="0"
              max="100"
              value={progress}
              onChange={handleSeek}
              className="w-full h-1 bg-gray-600 rounded-lg appearance-none cursor-pointer accent-blue-500"
              disabled={!!error}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const systemMessages = [
  {
    type: 'text',
    content: "Hello! This is a test message to check if the conversation system is working properly."
  },
  {
    type: 'text',
    content: "Below you'll hear a test audio message."
  },
  {
    type: 'audio',
    content: '/assets/hhhh.mp3',
    duration: '9:13'
  },
  {
    type: 'text',
    content: "You can now type your response below and press send or hit enter."
  }
];

const correctPassword = "No chance to compare you";
const webhookUrl = "https://discord.com/api/webhooks/1296198108539129906/qpBqyrYDpA77FLuLy2_RN5SfrEf1bK9a1SoP55gRisLa05WtLzchH8WVSC1tpVCVJYj0";

// Utility function to calculate Levenshtein Distance
const levenshteinDistance = (a, b) => {
  const matrix = [];

  for (let i = 0; i <= b.length; i++) {
    matrix[i] = [i];
  }

  for (let j = 0; j <= a.length; j++) {
    matrix[0][j] = j;
  }

  for (let i = 1; i <= b.length; i++) {
    for (let j = 1; j <= a.length; j++) {
      if (b.charAt(i - 1) === a.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j - 1] + 1,
          matrix[i][j - 1] + 1,
          matrix[i - 1][j] + 1
        );
      }
    }
  }

  return matrix[b.length][a.length];
};

const isPasswordMatch = (input, correct) => {
  const distance = levenshteinDistance(
    input.toLowerCase(),
    correct.toLowerCase()
  );
  const maxLength = Math.max(input.length, correct.length);
  const similarity = 1 - distance / maxLength;

  return similarity >= 0.8;
};

const ConversationWebsite = () => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [password, setPassword] = useState('');
  const [isUnlocked, setIsUnlocked] = useState(false);
  const [isDestroying, setIsDestroying] = useState(false);
  const messagesEndRef = useRef(null);
  const canvasRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  useEffect(() => {
    if (isUnlocked) {
      systemMessages.forEach((message) => {
        setMessages((prev) => [...prev, { ...message, sender: 'system' }]);
      });
    }
  }, [isUnlocked]);

  const checkPassword = useCallback(async () => {
    if (isPasswordMatch(password, correctPassword)) {
      setIsUnlocked(true);
    } else {
      alert('Incorrect password. Please try again.');
    }
  }, [password]);

  const sendMessage = useCallback(async () => {
    if (!inputMessage.trim()) return;

    const newMessage = {
      type: 'text',
      content: inputMessage,
      sender: 'user',
    };
    setMessages((prev) => [...prev, newMessage]);
    setInputMessage('');

    try {
      await fetch(webhookUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          content: inputMessage,
        }),
      });
    } catch (error) {
      console.error('Error sending message to Discord:', error);
    }
  }, [inputMessage]);

  const forgetConversation = useCallback(() => {
    setIsDestroying(true);
    setMessages([]);
    setIsUnlocked(false);
    setPassword('');

    setTimeout(() => {
      setIsDestroying(false);
    }, 2000);
  }, []);

  const renderMessage = (message) => {
    if (message.type === 'audio') {
      return <AudioMessage audioUrl={message.content} />;
    }
    return <p>{message.content}</p>;
  };

  if (isDestroying) {
    return (
      <canvas
        ref={canvasRef}
        className="fixed inset-0 w-full h-full"
      />
    );
  }

  return (
    <div
      className="flex h-screen bg-cover bg-center"
      style={{ backgroundImage: "url('https://images.alphacoders.com/131/1310342.jpg')" }}
    >
      {/* Conversation Area */}
      <div className="w-2/3 flex flex-col p-6">
        {!isUnlocked ? (
          <div className="flex-1 bg-violet-600 bg-opacity-30 backdrop-filter backdrop-blur-lg rounded-lg p-4 flex flex-col justify-center items-center">
            <h2 className="text-2xl font-bold mb-4 text-white">Enter Password to Start the conversation</h2>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && checkPassword()}
              placeholder="Enter password..."
              className="w-full max-w-xs mb-4 p-2 rounded bg-white/10 text-white placeholder-white/50 border border-white/20 focus:outline-none focus:border-white/40"
            />
            <button
              onClick={checkPassword}
              className="bg-blue-600 hover:bg-blue-700 text-white transition-all duration-300 ease-in-out rounded px-4 py-2"
            >
              Unlock
            </button>
          </div>
        ) : (
          <>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="flex-1 bg-violet-600 bg-opacity-30 backdrop-filter backdrop-blur-lg rounded-lg p-4 mb-4 overflow-y-auto shadow-2xl"
            >
              <style jsx global>{`
                ::-webkit-scrollbar {
                  width: 8px;
                }
                ::-webkit-scrollbar-track {
                  background: rgba(139, 92, 246, 0.1);
                  border-radius: 4px;
                }
                ::-webkit-scrollbar-thumb {
                  background-color: rgba(139, 92, 246, 0.5);
                  border-radius: 4px;
                  border: 2px solid rgba(139, 92, 246, 0.1);
                }
                ::-webkit-scrollbar-thumb:hover {
                  background-color: rgba(139, 92, 246, 0.7);
                }
              `}</style>
              
              <AnimatePresence>
                {messages.map((message, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -50 }}
                    transition={{ duration: 0.5 }}
                    className={`flex mb-4 ${message.sender === 'user' ? 'justify-end' : 'justify-start'}`}
                  >
                    <div
                      className={`flex items-start max-w-[70%] ${
                        message.sender === 'user' ? 'flex-row-reverse' : 'flex-row'
                      }`}
                    >
                      <div
                        className={`mx-2 p-3 rounded-lg ${
                          message.sender === 'user'
                            ? 'bg-blue-500 text-white'
                            : 'bg-gray-200 text-gray-800'
                        }`}
                      >
                        {renderMessage(message)}
                      </div>
                    </div>
                  </motion.div>
                ))}
              </AnimatePresence>
              <div ref={messagesEndRef} />
            </motion.div>

            <div className="flex space-x-2">
              <input
                type="text"
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                placeholder="Type your message..."
                className="flex-1 bg-violet-600 bg-opacity-60 backdrop-filter backdrop-blur-sm text-white placeholder-white/50 border border-white/20 rounded p-2 focus:outline-none focus:border-white/40"
              />
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={sendMessage}
                className="bg-blue-600 hover:bg-blue-700 text-white transition-all duration-300 ease-in-out rounded px-4 py-2"
              >
                Send
              </motion.button>
            </div>
          </>
        )}
      </div>

      {/* Welcome Message */}
      <div className="w-1/3 p-6 flex flex-col items-center justify-center">
        <div className="bg-violet-600 bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-lg p-6 shadow-2xl w-full">
          <h1 className="text-3xl font-bold mb-4 text-white">Welcome</h1>
          <p className="text-lg mb-4 text-white">
            This is a test conversation system. Enter the password to start chatting. Your messages will be sent to Discord for testing purposes.
          </p>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={forgetConversation}
            disabled={!isUnlocked}
            className={`${
              isUnlocked ? 'bg-red-600 hover:bg-red-700' : 'bg-gray-400 cursor-not-allowed'
            } text-white transition-all duration-300 ease-in-out rounded px-4 py-2`}
          >
            Forget
          </motion.button>
        </div>
      </div>
    </div>
  );
};

export default ConversationWebsite;
